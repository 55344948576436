import React from "react";
import Header from "../header/Header";
import Home from "../home/Home";
import Footer from "../footer/Footer";
import { QueryClient, QueryClientProvider } from "react-query";
import DashboardProvider from "./DashboardProvider";
import OurMission from "../aboutUs/OurMission";
import { Redirect, Route, Switch } from "react-router-dom";
import SeniorOfficers from "../aboutUs/SeniorOfficers";
import DivisionalACPs from "../aboutUs/DivisionalACPs";
import PuneCityPoliceMap from "../aboutUs/PuneCityPoliceMap";
import Initiative from "../aboutUs/Initiative";
import Gallery from "../aboutUs/Gallery";
import OrganizationalStructure from "../aboutUs/OrganizationalStructure";
import RightToInformation from "../citizenCorner/RightToInformation";
import Atrocities from "../others/Atrocities";
import UsefulWebsites from "../citizenCorner/UsefulWebsites";
import ArmLicenseForms from "../citizenCorner/ArmLicenseForms";
import CitizenAlertWall from "../citizenCorner/CitizenAlertWall";
import FAQ from "../citizenCorner/FAQ";
import Tenders from "../citizenCorner/Tenders";
import SafetyTips from "../citizenCorner/SafetyTips";
import InformationForPoliceOfficersMen from "../policeCorner/InformationForPoliceOfficersMen";
import MediaCoverage from "../policeCorner/MediaCoverage";
import PressRelease from "../policeCorner/PressRelease";
import CrimeReview from "../policeCorner/CrimeReview";
import NCRBStatisticalInformation from "../others/NCRBStatisticalInformation";
import Externee from "../others/Externee";
import DrunkDriveCases from "../others/DrunkDriveCases";
import CrimeStatistics from "../others/CrimeStatistics";
import AbsconderList from "../others/AbsconderList";
import InformationOfLllegalActivities from "../others/InformationOfLllegalActivities";
import PoliceRecruitment from "../policeCorner/PoliceRecruitment";
import DarpanBook from "../policeCorner/DarpanBook";
import GoodWork from "../policeCorner/GoodWork";
import Disclaimer from "../others/Disclaimer";
import { map } from "lodash";
import AccidentCompensation from "../citizenCorner/AccidentCompensation";
import ControlRoom from "../branch/ControlRoom";
import CrimeBranch from "../branch/CrimeBranch";
import SpecialBranch from "../branch/SpecialBranch";
import PoliceHeadQuarter from "../branch/PoliceHeadQuarter";
import TrafficBranch from "../branch/TrafficBranch";
import RightToService from "../citizenCorner/RightToService";
import LostAndFound from "../reportUs/LostAndFound";
import Feedback from "../reportUs/Feedback";
import ConfidentialInfo from "../reportUs/ConfidentialInfo";
import StoriesOfSacrificeAndBravery from "../others/StoriesOfSacrificeAndBravery";
import SearchResult from "../header/SearchResult";
import SpecialUnitBranch from "../branch/SpecialUnitBranch";
import LostAndFoundDetails from "../reportUs/LostAndFoundDetails";
import ScrollToTop from "react-scroll-to-top";
import CaseStudies from "../MySafePune/CaseStudies";
import Conclusion from "../MySafePune/Conclusion";
import MySafePune from "../MySafePune";
import HowItWorks from "../MySafePune/HowItWorks";
import NeedForProject from "../MySafePune/NeedForProject";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export const routes = [
  { path: "/", component: Home, exact: true },

  { path: "/about-us/our-mission", component: OurMission },
  { path: "/about-us/senior-officers", component: SeniorOfficers },
  { path: "/about-us/divisional-acps", component: DivisionalACPs },
  {
    path: "/about-us/organizational-structure",
    component: OrganizationalStructure,
  },
  { path: "/about-us/pune-city-police-map", component: PuneCityPoliceMap },
  { path: "/about-us/initiatives", component: Initiative },
  { path: "/about-us/gallery", component: Gallery },

  {
    path: "/citizen-corner/accident-compensation",
    component: AccidentCompensation,
  },
  {
    path: "/citizen-corner/right-to-information",
    component: RightToInformation,
  },
  // { path: "/citizen-corner/right-to-service", component: RightToService },
  { path: "/citizen-corner/useful-websites", component: UsefulWebsites },
  { path: "/citizen-corner/arm-license-forms", component: ArmLicenseForms },
  { path: "/citizen-corner/citizen-alert-wall", component: CitizenAlertWall },
  { path: "/citizen-corner/tenders", component: Tenders },
  { path: "/citizen-corner/faq", component: FAQ },
  { path: "/citizen-corner/safety-tips", component: SafetyTips },

  {
    path: "/police-corner/information-for-police-officers-men",
    component: InformationForPoliceOfficersMen,
  },
  // { path: "/police-corner/media-coverage", component: MediaCoverage },
  { path: "/police-corner/press-release", component: PressRelease },
  { path: "/police-corner/crime-review", component: CrimeReview },
  { path: "/police-corner/police-recruitment", component: PoliceRecruitment },
  { path: "/police-corner/good-work", component: GoodWork },
  { path: "/police-corner/darpan-book", component: DarpanBook },

  { path: "/report-us/lost-and-found", component: LostAndFound },
  { path: "/report-us/lost-and-found-details", component: LostAndFoundDetails },
  { path: "/report-us/feedback", component: Feedback },
  { path: "/report-us/confidential-info", component: ConfidentialInfo },

  { path: "/branch/control-room", component: ControlRoom },
  { path: "/branch/crime-branch", component: CrimeBranch },
  { path: "/branch/special-branch", component: SpecialBranch },
  { path: "/branch/police-head-quarter", component: PoliceHeadQuarter },
  { path: "/branch/traffic-branch", component: TrafficBranch },
  { path: "/branch/special-units", component: SpecialUnitBranch },

  { path: "/atrocities", component: Atrocities },
  {
    path: "/ncrb-statistical-information",
    component: NCRBStatisticalInformation,
  },
  { path: "/externee", component: Externee },
  { path: "/drunk-drive-cases", component: DrunkDriveCases },
  { path: "/crime-statistics", component: CrimeStatistics },
  { path: "/absconder-list", component: AbsconderList },
  {
    path: "/information-of-illegal-activities",
    component: InformationOfLllegalActivities,
  },
  { path: "/disclaimer", component: Disclaimer },
  {
    path: "/stories-of-sacrifice-and-bravery",
    component: StoriesOfSacrificeAndBravery,
  },
  { path: "/search", component: SearchResult },
  { path: "/my-safe-pune", component: MySafePune },
];

function Dashboard() {
  return (
    <DashboardProvider>
      <QueryClientProvider client={queryClient}>
        <Header />
        <Switch>
          {map(routes, (route) => (
            <Route {...route} key={route.path} />
          ))}
          <Redirect from="/LostFoundReg" to="/report-us/lost-and-found" />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </QueryClientProvider>
      <ScrollToTop smooth color="#faa652" />
    </DashboardProvider>
  );
}

export default Dashboard;
