import translationText from '../../translation.js'

const navLinks = [{
    ...translationText.AboutUs,
    subLinks: [{
        ...translationText.OurMission,
        link: "/about-us/our-mission"
    }, {
        ...translationText.SeniorPoliceOfficers,
        link: "/about-us/senior-officers"
    }, {
        ...translationText.DivisionalACP,
        link: "/about-us/divisional-acps"
    }, {
        ...translationText.OrganizationalStructure,
        link: "/about-us/organizational-structure"
    }, {
        ...translationText.PuneCityPoliceMap,
        link: "/about-us/pune-city-police-map"
    }, {
        ...translationText.Initiatives,
        link: "/about-us/initiatives"
    }, {
        ...translationText.Gallery,
        link: "/about-us/gallery"
    }]
},
{
    ...translationText.CitizenCorner,
    subLinks: [
        {
            ...translationText.SearchAndViewPublishedFIR,
            href: "https://citizen.mahapolice.gov.in/Citizen/MH/PublishedFIRs.aspx"
        },
        {
            ...translationText.AccidentCompensation,
            link: "/citizen-corner/accident-compensation"
        },
        {
            ...translationText.RightToInformation,
            link: "/citizen-corner/right-to-information"
        },
        // {
        //     ...translationText.RightToService,
        //     link: "/citizen-corner/right-to-service"
        // },
        {
            ...translationText.UsefulWebsites,
            link: "/citizen-corner/useful-websites"
        },
        {
            ...translationText.ArmLicenseForms,
            link: "/citizen-corner/arm-license-forms"
        },
        {
            ...translationText.CitizenAlertWall,
            link: "/citizen-corner/citizen-alert-wall"
        },
        {
            ...translationText.SafetyTips,
            link: "/citizen-corner/safety-tips"
        },
        {
            ...translationText.Tenders,
            link: "/citizen-corner/tenders"
        },
        {
            ...translationText.FAQ,
            link: "/citizen-corner/faq"
        },
    ]
}, {
    ...translationText.PoliceCorner,
    subLinks: [
        {
            ...translationText.InformationForPoliceOfficersMen,
            link: "/police-corner/information-for-police-officers-men"
        },
        // {
        //     ...translationText.MediaCoverage,
        //     link: "/police-corner/media-coverage"
        // },
        {
            ...translationText.PressRelease,
            link: "/police-corner/press-release"
        },
        {
            ...translationText.CrimeReview,
            link: "/police-corner/crime-review"
        },
        {
            ...translationText.PoliceRecruitment,
            link: "/police-corner/police-recruitment"
        },
        {
            ...translationText.GoodWork,
            link: "/police-corner/good-work"
        },
        {
            ...translationText.DarpanBook,
            link: "/police-corner/darpan-book"
        },
    ]
}, {
    ...translationText.ReportUs,
    subLinks: [
        {
            ...translationText.OnlineComplaint,
            href: "https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
        },
        {
            ...translationText.LostAndFound,
            link: "/report-us/lost-and-found"
        },
        {
            ...translationText.TenantInformation,
            href: "https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
        },
        {
            ...translationText.Feedback,
            link: "/report-us/feedback"
        },
        {
            ...translationText.ConfidentialInfo,
            link: "/report-us/confidential-info"
        },
    ]
}, {
    ...translationText.Branch,
    subLinks: [
        {
            ...translationText.ControlRoom,
            link: "/branch/control-room"
        },
        {
            ...translationText.CrimeBranch,
            link: "/branch/crime-branch"
        },
        {
            ...translationText.SpecialBranch,
            link: "/branch/special-branch"
        },
        {
            ...translationText.PoliceHeadQuarter,
            link: "/branch/police-head-quarter"
        },
        {
            ...translationText.TrafficBranch,
            link: "/branch/traffic-branch"
        },
    ]
},
]

export default navLinks