import React from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import useGetResponse from "../../apis/useGetResponse";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import { map, size } from "lodash";
import { Container } from "react-bootstrap";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderLocale from "../../common/component/fragments/RenderLocale";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const specialunits = {
  5: "/branch/control-room",
  1: "/branch/crime-branch",
  11: "/branch/special-branch",
  9: "/branch/police-head-quarter",
  13: "/branch/traffic-branch",
};

const getBaseUrl = ({ t_name, id }) => {
  if (t_name === "faq") return "/citizen-corner/faq";
  if (t_name === "gwork") return "/about-us/initiatives";
  if (t_name === "seniorofficers") return "/about-us/senior-officers";
  if (t_name === "tenders") return "/citizen-corner/tenders";
  if (t_name === "policestations")
    return "/about-us/pune-city-police-map?ps=" + id;
  if (t_name === "divofficers") return "/about-us/divisional-acps";
  if (t_name === "search") return "/";
  if (t_name === "cpdesk") return "/";
  if (t_name === "gallery") return "/about-us/gallery";
  if (t_name === "disclaimer") return "/disclaimer";
  if (t_name === "impweb") return "/citizen-corner/useful-websites";
  if (t_name === "prelease") return "/police-corner/press-release";
  // if (t_name === "rti") return "/citizen-corner/right-to-service";
  if (t_name === "recruitment") return "/police-corner/police-recruitment";
  if (t_name === "safetytips") return "/citizen-corner/safety-tips";
  // if (t_name === "media_coverage") return "/police-corner/media-coverage";
  if (t_name === "postivestory") return "/police-corner/good-work";
  if (t_name === "ac") return "/citizen-corner/accident-compensation";
  if (t_name === "specialunits")
    return specialunits[id] || "/branch/special-units?speId=" + id;

  // if (t_name === "missing_persons") return "MissingPerson";
  // if (t_name === "missing_persons1") return "MissingPerson";
  // if (t_name === "history") return "History";
  // if (t_name === "martyrs") return "Martyrs";
  // if (t_name === "bandmaster") return "PoliceBand";
  // if (t_name === "download") return "Download";
  // if (t_name === "miscellaneous") return "Miscellaneous";
  // if (t_name === "responsibilities") return "responsibilities";
  // if (t_name === "hierarchy") return "hir";
  // if (t_name === "welfare") return "Welfare";
  // if (t_name === "policefoundation") return "PoliceFoundation";
  // if (t_name === "contacts_no") return "impcontacts";

  // if (t_name === "stolen") return "stolen_vehicles";
  // if (t_name === "dcpvisit") return "Dcp_visit";

  return "/";
};

function SearchResult() {
  const query = useQuery();
  const { data, isFetching, isError } = useGetResponse(
    queryString.stringifyUrl({
      url: "search",
      query: {
        name: query.get("name") || "",
      },
    })
  );

  return (
    <Page isLoading={isFetching || isError} pageTitle={translationText.Search}>
      <Container className="mt-4" id="bravery-wrapper">
        {/* <GlobalSearch style={{ position: "relative", height: "auto" }} /> */}
        <div className="container-fluid">
          <div className="row">
            {(!!data || !!size(data)) && (
              <RenderLocale
                keyName="NoSearchResult"
                className="text-center"
                style={{ fontSize: "1.5rem" }}
              />
            )}
            {map(data, (d) => (
              <div className="col-12 mt-3 search-card">
                <div className="card">
                  <div
                    className="card-horizontal"
                    style={{ display: "flex", flex: 1 }}
                  >
                    <div className="img-square-wrapper">
                      <img
                        className=""
                        src="/images/logo.png"
                        alt="search"
                        style={{ width: 100, padding: "1rem" }}
                      />
                    </div>
                    <div className="card-body">
                      <Link
                        to={`${getBaseUrl(d)}`}
                        style={{ color: "#212529" }}
                      >
                        <h5 className="card-title">{d.title}</h5>
                        <p
                          className="card-text"
                          dangerouslySetInnerHTML={{ __html: d.description }}
                          style={{ marginBottom: "2rem" }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Page>
  );
}

export default SearchResult;
