import preval from "preval.macro";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import Ticker from "react-ticker";
import useGetResponse from "../../apis/useGetResponse";
import { translateNumerals } from "../../utils";
import { useDashboardContext } from "../dashboard/DashboardProvider";
import { LANGUAGES } from "../../common/constants/other";
import moment from "moment";

var yesterday = moment().subtract(1, "day");

const data = [
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/digital-india-logo.png`,
    link: "http://www.digitalindia.gov.in/",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/mha-logo.png`,
    link: "http://mha.nic.in/",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/india-gov-logo.png`,
    link: "https://india.gov.in/ ",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/cbi.png`,
    link: "http://www.cbi.gov.in/ ",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/bprd.png`,
    link: "http://bprd.nic.in/ ",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/mygov-footer-logo.png`,
    link: "https://innovate.mygov.in/",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/makeinindia.png`,
    link: "http://www.makeinindia.com/home ",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/nic.png`,
    link: "http://www.nic.in/",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/maharashtra.png`,
    link: "https://www.maharashtra.gov.in/1125/Home",
  },
  {
    img: `${process.env.REACT_APP_API_BASE_URL}/img/the_govt/pm-india-logo.png`,
    link: "http://www.pmindia.gov.in/en/",
  },
];

const Footer = () => {
  const {
    data: visitorCount,
    isFetching,
    isError,
  } = useGetResponse("counterdetails");
  const { language } = useDashboardContext();

  return (
    <Container fluid className="noprint">
      <Row>
        <Col className="p-0">
          <img
            alt=""
            src="/images/footer-image.png"
            height="176"
            id="footer-image"
            className="d-block align-top m-auto"
          />
        </Col>
      </Row>
      <Row>
        <Col className="footer-logo">
          <Ticker offset={10}>
            {({ index }) => (
              <>
                <a
                  href={data[index % 10]?.link}
                  target="_blank"
                  key={index}
                  rel="noreferrer"
                >
                  <img alt={"footer"} src={data[index % 10]?.img} />
                </a>
              </>
            )}
          </Ticker>
        </Col>
      </Row>
      <Row id="footer-navbar" className="flex-column flex-md-row">
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="WhatsApp" />
          </strong>
        </Col>
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="WomenHelpline" />
          </strong>
        </Col>
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="PoliceControlRoom" />
          </strong>
        </Col>
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="ElderHelpLine" />
          </strong>
        </Col>
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="ChildHelpLine" />
          </strong>
        </Col>
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="PuneInspectorEOW" />
            <div>{`${translateNumerals(
              "020",
              language === LANGUAGES[0] ? "marathi" : "english"
            )}-${translateNumerals(
              "26208285",
              language === LANGUAGES[0] ? "marathi" : "english"
            )}`}</div>
          </strong>
        </Col>
      </Row>
      <Row id="footer-nav-options" className="pt-5 flex-column flex-md-row p-5">
        <Col md={4}>
          <p className="py-3 m-0">
            <RenderLocale keyName="PuneCityPolice" />
          </p>
          <Row>
            <Col md={6}>
              <ul>
                <li>
                  <Link to="/police-corner/press-release">
                    <RenderLocale keyName="PressRelease" />
                  </Link>
                </li>
                <li>
                  <Link to="/citizen-corner/faq">
                    <RenderLocale keyName="FAQ" />
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/gallery">
                    <RenderLocale keyName="Gallery" />
                  </Link>
                </li>
                <li>
                  <a href="/sitemap.xml" target="_blank">
                    <RenderLocale keyName="SiteMap" />
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <ul>
                <li>
                  <Link to="/about-us/senior-officers">
                    <RenderLocale keyName="SeniorOfficerList" />
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/divisional-acp">
                    <RenderLocale keyName="DivisionalACP" />
                  </Link>
                </li>
                <li>
                  <Link to="/police-corner/police-recruitment">
                    <RenderLocale keyName="PoliceRecruitment" />
                  </Link>
                </li>
                <li>
                  <Link to="/disclaimer">
                    <RenderLocale keyName="Disclaimer" />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col md={5}>
          <p className="py-3 m-0">
            <RenderLocale keyName="InformationAndServices" />
          </p>
          <Row>
            <Col md={6}>
              <ul>
                <li>
                  <Link to="/citizen-corner/safety-tips">
                    <RenderLocale keyName="SafetyTips" />
                  </Link>
                </li>
                <li>
                  <Link to="/citizen-corner/citizen-alert-wall">
                    <RenderLocale keyName="CitizenAlertWall" />
                  </Link>
                </li>
                <li>
                  <Link to="/about-us/initiatives">
                    <RenderLocale keyName="Initiatives" />
                  </Link>
                </li>
                <li>
                  <Link to="/citizen-corner/arm-license-forms">
                    <RenderLocale keyName="ArmLicenseForms" />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <ul>
                <li>
                  <Link to="/information-of-illegal-activities">
                    <RenderLocale keyName="InformationOfIllegalActivities" />
                  </Link>
                </li>
                <li>
                  <a
                    href="https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RenderLocale keyName="UnidentifiedDeadBodies" />
                  </a>
                </li>
                <li>
                  <Link to="/citizen-corner/useful-websites">
                    <RenderLocale keyName="UsefulWebsites" />
                  </Link>
                </li>
                <li>
                  <a href="http://punenoc.in/" target="_blank" rel="noreferrer">
                    <RenderLocale keyName="ExcavationNocApplication" />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-md-3">
          <p className="py-3 m-0">
            <RenderLocale keyName="QuickLinks" />
          </p>
          <Row>
            <Col>
              <ul>
                <li>
                  <Link to="/citizen-corner/tenders">
                    <RenderLocale keyName="Tenders" />
                  </Link>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
                  >
                    <RenderLocale keyName="OnlineComplaint" />
                  </a>
                </li>
                <li>
                  <Link to="/report-us/lost-and-found">
                    <RenderLocale keyName="LostAndFound" />
                  </Link>
                </li>
                <li>
                  <Link to="/report-us/confidential-info">
                    <RenderLocale keyName="ConfidentialInfo" />
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row id="footer-navbar-2" className="flex-column flex-md-row">
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="VisitorCount" />
            {translateNumerals(
              visitorCount,
              language === LANGUAGES[0] ? "marathi" : "english"
            )}
          </strong>
        </Col>
        <Col className="my-auto text-center p-2">
          <strong>
            <RenderLocale keyName="LastUpdatedOn" />
            {yesterday.format("DD/MM/YYYY")}
            {", 8:07:04 PM"}
          </strong>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
